<template>
  <div>
    <div class="details__description__wrapper">
        <h2>Why Make a <span class="color__title">{{ element.title }}</span> With Us?</h2>
        <p>{{ element.reason }}</p>
        <ul v-for="element in element.points" :key="element">
        <li>{{ element }}</li>
        </ul>
    </div>
    
  </div>
</template>

<script>
import data from "../../assets/servicedata.json";
export default {
  data() {
    const type = this.$route.params.type;
    const id = this.$route.params.id;
    const element = data.services
      .find((element) => element[type])[type].find((element) => element.id == id);

    return {
      element: element,
    };
  },
};
</script>

<style lang="scss" scoped>
.details__description__wrapper{
    margin-bottom: 7em;
    h2{
        font-family: "Raleway", sans-serif;
        font-weight: 700;
        font-size: 35px;
        width: 100%;
    }
    .color__title {
        color: #00a99d;
    }
    p,ul {
        margin: auto;
        font-weight: 700;
    }
    p {
        width: 38%;
        margin-bottom: 2em;
    }
    ul {
        width: 25%;
        text-align: left;
    }
}
@media only screen and (min-width: 1024px) {
  .details__title {
    font-size: 55px;
  }
}
@media only screen and (max-width: 728px) {
  .details__description__wrapper{
    h2{
        font-size: 25px;
    }
     p {
        width: 80%;
    }
    ul {
        width: 50%;
        text-align: left;
    }
  }
}

</style>
