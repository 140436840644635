<template>
  <div>
    <div class="details__description__wrapper">
      <div class="details__content">
        <source
          :srcset="require(`@/assets/images/service/${element.path}.svg`)"
          type="image/jpeg"
        />
        <img
          class="service__pic"
          :src="require(`@/assets/images/service/${element.path}.svg`)"
        />
        <div class="details__texts">
          <div>
            <h1 class="details__title">{{ element.title }}</h1>
            <p>{{ element.details }}</p>
          </div>
        </div>
      </div>
    </div>
    <picture class="testimonial__curves">
      <source
        media="(max-width: 699px)"
        :srcset="require(`@/assets/images/background/curve-mobile.webp`)"
        type="image/webp"
      />
      <source
        media="(max-width: 699px)"
        :srcset="require(`@/assets/images/background/curve-mobile.png`)"
        type="image/png"
      />
      <source
        media="(min-width: 700px)"
        :srcset="require(`@/assets/images/background/curve-web.webp`)"
        type="image/webp"
      />
      <source
        media="(min-width: 700px)"
        :srcset="require(`@/assets/images/background/curve-web.png`)"
        type="image/png"
      />
      <img
        :src="require(`@/assets/images/background/curve-web.webp`)"
        alt="bottom_curves"
        width="100%"
      />
    </picture>
  </div>
</template>

<script>
import data from "../../assets/servicedata.json";
export default {
  data() {
    const type = this.$route.params.type;
    const id = this.$route.params.id;
    const element = data.services
      .find((element) => element[type])[type].find((element) => element.id == id);

    return {
      element: element,
    };
  },
};
</script>

<style lang="scss" scoped>
.details__description__wrapper {
  background-color: #37484f;
  width: 100%;
  display: flex;
  justify-content: center;
  .details__content {
    color: white;
    margin: 120px 0 40px 0;
    width: 80vw;
    max-width: 1000px;
    font-size: 15px;
    display: flex;
    .details__texts {
      text-align: left;
      margin-left: 80px;
      display: flex;
      align-items: center;
    }
    img {
      width: 250px;
    }
    .details__title {
      font-size: 55px;
    }
  }
}
.details__title {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 35px;
  width: 100%;
  margin: 0;
}
@media only screen and (min-width: 1024px) {
  .details__title {
    font-size: 55px;
  }
}
@media only screen and (max-width: 768px) {
  .details__description__wrapper {
    .details__content {
      display: block;
      .details__texts {
        text-align: center;
        margin-left: 0px;
        display: block;
      }
      img {
        width: 150px;
      }
      .details__title {
        font-size: 45px;
        margin-top: .5em;
      }
    }
  }
}
</style>
